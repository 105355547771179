import { useRef, useState } from "react";
import NavBar from "./NavBar";
import Notify from "../partials/Notify";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Outlet } from "react-router";

const Home = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const navRef = useRef<HTMLElement>(null);

  const handleMenu = () => {
    setShowNavbar(true);
  };

  const handleCloseNav = () => {
    setShowNavbar(false);
  };

  useOutsideClick(navRef, handleCloseNav);
  return (
    <div className="lg:grid lg:grid-cols-[16.5rem_1fr] h-screen overflow-hidden bg-clrBg dark:bg-clrDarkBg2">
      <NavBar
        isNavbarOpen={showNavbar}
        handleCloseNav={handleCloseNav}
        navRef={navRef}
      />
      <div className="grid h-screen grid-rows-[80px_1fr] ">
        <Notify handleMenu={handleMenu} />
        <section className="!overflow-y-auto" style={{overflowY: "auto"}}>
          <Outlet />
        </section>
      </div>
    </div>
  );
};

export default Home;
