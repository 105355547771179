import { useState } from "react";
import AddIcon from "../../assets/icons/wallet/add.svg";
import { AddNewCaption } from "./AdvertModals";
import { EmptyCard, TableLoader } from "../reusables";
import TableMain from "../reusables/ReactDataTable";
import { adsCaption } from "./data";
import { useCreateCaptionMutation, useGetCaptionsQuery } from "src/features/api/advertSlice";


const Caption = () => {
  const [isNewCaption, setNewCaption] = useState<boolean>(false);
  const { data, isLoading, isFetching } = useGetCaptionsQuery();

  const [createCaption, { isLoading: creatingCaption }] = useCreateCaptionMutation()

  const handleCaption = (data: { caption: string, is_active: boolean }) => {
    createCaption(data)
      .unwrap()
      .then(() => {
        setNewCaption(false)
      })
      .catch((err) => {
        console.error(err, "i am the error here")
      })
  }

  return (
    <section className="w-[96%] mx-auto mt-3 mb-6 h-full">
      <div className="px-4 pt-2.5 pb-20 mb-2 bg-white dark:bg-clrDarkBg rounded-lg md:px-10">
        <div>
          <div className="mt-4 mb-4 md:mb-9">
            <h3 className="text-xs font-semibold text-black md:text-sm dark:text-clrPlaceholder">
              Advert Captions {' '}
            </h3>
            <p className="text-x10 text-clrGray dark:text-clrPlaceholder">
              Write a caption you want your viewers to see when viewing your ads {' '}
            </p>
          </div>
          <button
            type="button"
            onClick={() => setNewCaption(!isNewCaption)}
            className="cursor-pointer flex justify-between items-center w-fit h-8 mt-3 px-4 gap-3 bg-primary rounded-[28px] transition duration-500 ease-in-out transform hover:bg-[#E5B604] hover:scale-[0.95]"
          >
            <img src={AddIcon} alt="Add URL" width={18} height={18} />
            <p className="text-xs font-medium text-white">{"New Caption"}</p>
          </button>
        </div>
        <div>
          {isLoading || isFetching ? (
            <TableLoader />
          ) : (
            <TableMain
              defaultData={data?.data || []}
              columns={adsCaption}
              totalPages={1}
              pageSize={data?.data?.length || 0}
              currentPage={1}
              setPage={() => { }}
            />
          )}
          {!isLoading && !isFetching && (!data?.data || data?.data.length === 0) && (
            <EmptyCard
              description={"No Caption"}
            />
          )}
        </div>
      </div>
      {isNewCaption && <AddNewCaption handleClick={handleCaption} handleModal={() => setNewCaption(!isNewCaption)} isLoading={creatingCaption} />}
    </section>
  );
};


export default Caption;
