import { useState } from "react";
import { SearchRow } from "./common";
import TrafficSummary from "./TrafficSummary";
import { exportTableData } from "src/helper";
import { useGetVisitedDomainQuery } from "src/features/api/analyticsSlice";
import { useParams } from "react-router-dom";

const TrafficAnalytics = () => {
  const [page, setPage] = useState<number>(1);
  const { analyticId } = useParams();
  const [filterYear, setFilterYear] = useState<string>(new Date().getFullYear().toString());
  const [filterTimeline, setFilterTimeline] = useState<string>('Yearly');
  const [filterMonth, setFilterMonth] = useState<string>('August');

  const { data, isLoading, isFetching } = useGetVisitedDomainQuery({
    deviceId: analyticId || '',
    timeLine: filterTimeline,
    month: filterMonth,
    year: filterYear
  });
  
  return (
    <section className="w-[96%] mx-auto pb-6 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-6 my-4">
        <div className="grid gap-7">
          <h3 className="text-sm font-semibold text-clrGray dark:text-white">
            Hub History
          </h3>
          <SearchRow 
            setFilterYear={setFilterYear} 
            filterYear={filterYear} 
            filterTimeline={filterTimeline}
            setFilterTimeline={setFilterTimeline}
            setFilterMonth={setFilterMonth}
            filterMonth={filterMonth}
            dataLength={data?.data?.length || 0}
            exportTable={() => exportTableData(data?.data || [], "Traffic Breakdown")}
          />
        </div>
        <TrafficSummary 
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          page={page}
          setPage={setPage}
          showSearchRow={false}
          filterYear={filterYear}
          setFilterYear={setFilterYear}
          filterTimeline={filterTimeline}
          setFilterTimeline={setFilterTimeline}
          filterMonth={filterMonth}
          setFilterMonth={setFilterMonth}
        />
      </div>
    </section>
  );
};

export default TrafficAnalytics;
