import { EmptyCard, TableLoader } from "../reusables";
import { useState } from "react";
import TableMain from "../reusables/ReactDataTable";
import { trafficDetailsColumn } from "./data";
import { SearchRow } from "./common";
import { useGetVisitedDomainDetailQuery } from "src/features/api/analyticsSlice";
import { useParams } from "react-router-dom";
import { exportTableData } from "src/helper";

const TrafficDetails = () => {
  const [page, setPage] = useState<number>(1);
  const { analyticName, trafficId, trafficCategory } = useParams();
  const [filterYear, setFilterYear] = useState<string>(new Date().getFullYear().toString());
  const [filterTimeline, setFilterTimeline] = useState<string>('Yearly');
  const [filterMonth, setFilterMonth] = useState<string>('August');
  const { data: visitedDomainDetail, isLoading, isFetching } = useGetVisitedDomainDetailQuery({
    timeLine: filterTimeline,
    year: filterYear,
    month: filterMonth,
    categoryId: analyticName || '',
    otherCategory: false,
    deviceId: trafficId || ''
  });

  return (
    <section className="w-[96%] mx-auto pb-6 mt-6 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-8 md:py-8">
        <div className="grid gap-7">
          <h3 className="text-sm font-semibold text-clrGray dark:text-white">
            View History
          </h3>
          <SearchRow
            setFilterYear={setFilterYear}
            filterYear={filterYear}
            filterTimeline={filterTimeline}
            setFilterTimeline={setFilterTimeline}
            setFilterMonth={setFilterMonth}
            filterMonth={filterMonth}
            exportTable={()=> exportTableData(visitedDomainDetail?.data || [], trafficCategory + "Details breakdown")}
            dataLength={visitedDomainDetail?.data?.length || 0}
          />
        </div>
        <div>
          {isLoading || isFetching ? (
            <TableLoader />
          ) : (
            <TableMain
              defaultData={visitedDomainDetail?.data}
              columns={trafficDetailsColumn}
              totalPages={0}
              pageSize={30}
              currentPage={page}
              setPage={setPage}
            />
          )}

          {!isLoading && !isFetching && (visitedDomainDetail?.data.length || 0) === 0 && (
            <EmptyCard description="You don't have any traffic analytics!" />
          )}
        </div>
      </div>

    </section>
  );
};

export default TrafficDetails;