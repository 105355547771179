import { useParams } from 'react-router-dom';
import { useGetDataUsageConnectedClientGraphResultsQuery, useGetUptimeConnectedClientGraphResultsQuery } from 'src/features/api/analyticsSlice';
import { BarChart } from "./BarChartSession";
import { useState } from "react";
import { BarChartUptimeProps } from "./Uptime";
import { BarChartUsageProps } from "./DataUsage";
import { SearchRow } from './common';
import { exportTableData } from 'src/helper';
import { EmptyCard, TableLoader } from '../reusables';


const ConnectionDetails = () => {
  const {analyticId, connectedUserId } = useParams();
  // State for filters
  const [filterYear, setFilterYear] = useState<string>(new Date().getFullYear().toString());
  const [filterTimeline, setFilterTimeline] = useState<string>('Yearly');
  const [filterMonth, setFilterMonth] = useState<string>('August');


  const { data: uptimeGraph, isLoading: gettingUptimeGraph, isFetching: fetchingUptimeGraph  } = useGetUptimeConnectedClientGraphResultsQuery({
    deviceId: analyticId || '',
    timeLine: filterTimeline,
    month: filterMonth,
    year: filterYear,
    connectedClient: connectedUserId || ''
  });

  const { data: dataUsageGraph, isLoading: gettingDataUsageGraph, isFetching: fetchingDataUsageGraph } = useGetDataUsageConnectedClientGraphResultsQuery({
    deviceId: analyticId || '',
    timeLine: filterTimeline,
    month: filterMonth,
    year: filterYear,
    connectedClient: connectedUserId || ''
  });

  const chartUptimeData: BarChartUptimeProps = {
    labels: uptimeGraph?.data.map(item => item.timePeriod) || [],
    data: uptimeGraph?.data.map(item => ({
      uptime: item.uptime,
      uptimeInSeconds: item.uptimeInSeconds,
      timePeriod: item.timePeriod
    })) || []
  };

  const chartData: BarChartUsageProps = {
    labels: dataUsageGraph?.data.map(item => item.timePeriod) || [],
    data: dataUsageGraph?.data.map(item => ({
      dataUsed: item.dataUsed,
      dataUsedInBytes: item.dataUsedInBytes,
      timePeriod: item.timePeriod,
    })) || []
  };

  return (
    <section className="w-[96%] mx-auto pb-6 overflow-y-auto">
      <div className="px-3 py-4 my-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-6 md:pt-6 md:pb-3">
        <div className="grid gap-1 mb-6 md:mb-8">
          <h3 className="text-sm font-semibold text-clrGray dark:text-white">
            Data Usage
          </h3>
          <SearchRow 
            setFilterYear={setFilterYear} 
            filterYear={filterYear} 
            filterTimeline={filterTimeline}
            setFilterTimeline={setFilterTimeline}
            setFilterMonth={setFilterMonth}
            filterMonth={filterMonth}
            exportTable={()=> exportTableData(dataUsageGraph?.data || [], "Overall Uptime")}
            dataLength={dataUsageGraph?.data?.length || 0}
          />
          {gettingDataUsageGraph || fetchingDataUsageGraph ? (
              <TableLoader />
            ) : (dataUsageGraph?.data?.length || 0) === 0 ? (
              <div className="flex flex-col items-center justify-center mt-5">
                <EmptyCard description={"No result found"} />
              </div>
            ) : (
              <BarChart chartData={chartData} />
            )}
        </div>
        <div className="grid gap-4 pb-4">
          <h3 className="text-sm font-semibold text-clrGray dark:text-white">
            Uptime{' '}
          </h3>
          {gettingUptimeGraph || fetchingUptimeGraph ? (
              <TableLoader />
            ) : (uptimeGraph?.data?.length || 0) === 0 ? (
              <div className="flex flex-col items-center justify-center">
                <EmptyCard description={"No result found"} />
              </div>
            ) : (
              <BarChart chartData={chartUptimeData} />
            )}
        </div>
      </div>
    </section>
  )
}

export default ConnectionDetails