import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardTitle, ButtonAuth, ErrorMsg, InputBox } from '../../reusables';
import AuthFrame from '../../reusables/AuthFrame';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down-Yellow.svg';
import { Country } from '../../../features/api/authSliceTypes';
import { setUser } from '../../../features/store/authReducerSlice';
import { GoogleSignUpDetails } from "../../../features/api/authSliceTypes";
import { jwtDecode } from "jwt-decode";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSignUpWithGoogleMutation } from 'src/features/api/authSlice';
import { SelectModal } from './Modals';


interface GoogleJwtPayload {
  email: string;
  given_name: string;
  family_name: string;
  sub: string;
  picture?: string;
  email_verified?: boolean;
  name?: string;
}

const GoogleSignUpDetailsPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [googleSignUp, { isLoading }] = useSignUpWithGoogleMutation();

  // Local states for input fields and error handling
  const [countryId, setCountryId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<Country>();

  // Modal states
  const [isCountryModalOpen, setIsCountryModalOpen] = useState<boolean>(false);

  // Handle country selection from modal
  const handleCountrySelect = (country: Country) => {
    setSelectedCountry(country);
    setCountryId(country.countryId);
    setIsCountryModalOpen(false);
  };

  const handleSubmit = async () => {
    // Validate inputs
    if (!countryId || !phoneNumber) {
      setError("Please fill out all fields");
      return;
    }

    try {
      let decodedToken;
      try {
        decodedToken = jwtDecode<GoogleJwtPayload>(state.idToken);
      } catch (error) {
        console.error('Failed to decode token:', error);
        setError("Something went wrong, Please try again");
        return;
      }

      const signUpData: GoogleSignUpDetails = {
        email: decodedToken.email,
        idToken: state.idToken,
        firstName: decodedToken.given_name || "",
        lastName: decodedToken.family_name || "",
        countryId: countryId || "",
        phoneNumber: phoneNumber || "",
      };

      const response = await googleSignUp(signUpData).unwrap();

      if (response?.success) {
        dispatch(setUser(response?.data));
        navigate("/home");
      }
    } catch (error: any) {
      setError(error.data.message || 'Something went wrong');
    }
  };

  return (
    <div className="flex">
      <Card>
        <div className="flex items-center flex-col mt-2 w-[300px] md:w-auto md:px-[68px] mx-auto mb-12 md:mb-8 h-full">
          <div className="text-left mb-12 mr-auto">
            <CardTitle title={'More Information'} />
          </div>

          <div className="w-full mb-4">
            <InputBox
              title={'Phone'}
              name="phone"
              type="tel"
              id="phone"
              value={phoneNumber}
              handleChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="08030000000"
            />
          </div>

          {/* Country Selection */}
          <div className="w-full mb-6">
            <label htmlFor="country" className="block text-[0.625rem] leading-3 text-black md:text-sm font-semibold mb-1.5">
              Country
            </label>

            <div className="relative">
              <div onClick={() => setIsCountryModalOpen(true)} className="cursor-pointer">
                {selectedCountry && (
                  <img
                    src={selectedCountry.logoUrl}
                    alt={selectedCountry.name}
                    className="absolute inset-y-0 left-0 top-[20%] h-5 w-auto"
                  />
                )}
                <input
                  className="w-full pl-10 pr-4 py-2 border-b-[1px] border-primary text-sm font-medium focus:outline-none"
                  value={selectedCountry?.name || ''}
                  placeholder={'Select Country'}
                  readOnly
                />
                <ArrowDown
                  className="absolute right-0 top-[20%]"
                  width={20}
                  height={20}
                />
              </div>
            </div>
          </div>

          <ErrorMsg errMsg={error} />

          <div className="mt-6 mb-8 w-[90%]">
            <ButtonAuth
              type="submit"
              loading={isLoading}
              handleClick={handleSubmit}
              text={'Finish'}
            />
          </div>

        </div>
      </Card>
      <AuthFrame />

      {/* Country Selection Modal */}
      {isCountryModalOpen && (
        <SelectModal
          type="country"
          handleCloseModal={() => setIsCountryModalOpen(false)}
          handleGoBack={() => setIsCountryModalOpen(false)}
          handleSubmit={handleCountrySelect}
        />
      )}
    </div>
  );
};

export default GoogleSignUpDetailsPage;
