import { useRef, useState } from "react";
import { EmptyCard, TableLoader } from "../reusables";
// import { ReactComponent as ChevronRightIcon } from "../../assets/icons/devices-chevron-right.svg";
import { SubscriptionDevice } from "src/features/api/parentalControlSliceType";
import { useGetDeviceAnalyticsSummaryQuery } from "src/features/api/analyticsSlice";
import { SubscriptionDetail } from "./SubscriptionDetail";
import useOutsideClick from "src/hooks/useOutsideClick";


const DeviceList = () => {
    const [hubName, saveHubName] = useState("");
    // Th search param is not available at the moment
    // const [option, toggleOption] = useState<string>('');

    // const modalRef = useRef<HTMLElement>(null);
    // const [filterStatus, setFilterStatus] = useState<string>('All');
    const { data: devices, isLoading: gettingAllHubs } = useGetDeviceAnalyticsSummaryQuery({ search: hubName, page: 1, pageSize: 30 });

    // Calculate the length of device if available
    const deviceLength = devices?.data?.records?.length || 0;

    // const handleFilterStatus = (status: string) => {
    //     if (status === 'All') {
    //         setFilterStatus(''); // Set to empty string if "All" is selected
    //     } else {
    //         setFilterStatus(status); // Otherwise, set to the selected status
    //     }
    // };
    return (
        <div className="pb-20 bg-white dark:bg-clrDarkBg rounded-lg">
            <div className="flex w-full gap-3 md:w-auto mt-4">
                {/* <MobileFilterSearch>
                    <Filter
                        options={['All', 'Online', 'Offline', 'Blacklisted']}
                        toggleFilter={toggleOption}
                        selectedFilterOption={option}
                        currentFilterOption={'STATUS'}
                        selectFilterOption={handleFilterStatus}
                        selectedOption={filterStatus}
                        modalRef={modalRef}
                        label={'Status'}
                    />
                </MobileFilterSearch> */}
                <div className="flex-1 md:flex-auto ">
                    <label
                        htmlFor=""
                        className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
                    >
                        Search
                    </label>
                    <input
                        type="text"
                        name="Hub Name"
                        onChange={(e) => saveHubName(e.target.value)}
                        value={hubName}
                        placeholder="Hub Name"
                        className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
                    />
                </div>
            </div>

            <div className="mt-9">
                <h3 className="text-sm font-medium text-clrGray dark:text-clrPlaceholder">
                    Total Hubs <span>({devices?.data?.totalRecords})</span>
                </h3>
                {gettingAllHubs ? (
                    <TableLoader />
                ) : deviceLength > 0 ? (
                    <aside className="grid grid-cols-1 gap-3 pb-10 my-5 bg-white rounded-lg dark:bg-clrDarkBg md:grid-cols-3 md:gap-5">
                        {devices?.data?.records.map((device) => {
                            return (
                                <Hubs
                                    key={device?.id}
                                    device={device}
                                />
                            );
                        })}
                    </aside>
                ) : (
                    <EmptyCard description={"No Hubs Available"} />
                )
                }
            </div>
        </div>
    );
};


const Hubs = ({ device }: { device: SubscriptionDevice }) => {
    // const navigate = useNavigate();
    const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
    const modalRef = useRef<HTMLElement>(null);

    // const handleSubscripiton = () => {
    //     if(device.hasActiveSubscription){
    //         setIsSubscribed(!isSubscribed)
    //     }else{
    //         navigate(`/home/subscridption/${device.id}/${device.hubName}`)
    //     }
       
    // }


    useOutsideClick(modalRef, ()=> setIsSubscribed(!isSubscribed));

    return (
        <>
            <div className="grid grid-cols-3 gap-3 md:gap-4 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-2 md:p-4 shadow-wxl">
                <img
                    src={device.imageUrl}
                    alt={device.hubName}
                    className="object-cover rounded "
                />
                <div className="flex flex-col justify-between flex-1 h-full col-span-2">
                    <div className="flex items-center justify-between pt-1 lg:pt-1.5">
                        <p className="font-medium md:font-semibold text-x10 md:text-xs text-clrTextGray dark:text-white">
                            {device?.hubName.length > 15 ? `${device?.hubName.slice(0, 15)}...` : device?.hubName}
                        </p>
                    </div>
                    {/* {device.hasActiveSubscription &&
                        <button
                            onClick={() =>
                                navigate(`/home/parental-control/${device.id}/${device.hubName}`)
                            }
                            className="self-end"
                        >
                            <ChevronRightIcon />
                        </button>} */}
                    {/* <div className="justify-items-end md:w-[80%] mt-3 pb-1 lg:pb-1.5">
                        {device.hasActiveSubscription ? (
                            <Button
                                handleClick={handleSubscripiton}
                                text="View Details"
                                type="button"
                                wBtn="w-fit"
                                fontWeight="font-normal"
                                py="py-1"
                                textSize="text-x8"
                            />
                        ) : (
                            <Button
                                text="Pay for a Subscription"
                                handleClick={handleSubscripiton}
                                type="button"
                                wBtn="w-fit"
                                fontWeight="font-normal"
                                py="py-1"
                                textSize="text-x8"
                            />

                        )}
                    </div> */}
                </div>
            </div>
            {isSubscribed &&  <SubscriptionDetail modalRef={modalRef} handleCloseModal={()=> setIsSubscribed(!isSubscribed) } />}
        </>
    );
};


export default DeviceList;

