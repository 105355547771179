import { RefObject, useRef, useState } from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import useOutsideClick from "src/hooks/useOutsideClick";
import { InfoBoxProps, ProgressBoxProps } from 'types';
import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus";
import { ButtonYellow, Filter } from "../reusables";
import Export from '../../assets/icons/export.svg';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { ReactComponent as VerticalEllipsisIcon } from "../../assets/icons/hotspot-hubs/vertical-ellipsis.svg";
import { monthsOfYear } from "src/helper";
import { useDisconnectClientOnDeviceMutation, useRestrictClientOnDeviceMutation, useWhitelistClientOnDeviceMutation } from "src/features/api/deviceSlice";
import { ChooseModal } from "../reusables/Modal";
import { useParams } from "react-router-dom";
import { useGetListofYearsQuery } from "../../features/api/analyticsSlice"


export const SpeedDetail = ({
  imgSrc,
  title,
  value,
}: {
  imgSrc: string;
  title: string;
  value: string;
}) => {
  return (
    <div className="detail-item border border-[#FFCB0533] flex items-center gap-x-3 p-2 rounded-xl">
      <img alt="speed" src={imgSrc} className="h-5" />
      <div className="text-black text-[10.5px]">
        <p className="mb-1 text-clrGray">{title}</p>
        <p className="font-semibold">{value}</p>
      </div>
    </div>
  );
};

export const InfoBox = ({ label, value, flex, offline }: InfoBoxProps) => (
  <div className={`flex gap-3 ${flex ? "mt-0 w-full" : "mt-6"}`}>
    <div
      className={` w-1 rounded ${offline ? 'bg-[#FFECE5]' : 'bg-primary'}  ${!flex ? 'h-[46px]' : 'h-[23px]'
        }`}
    />
    <div className={`${flex ? 'flex gap-x-10 items-center justify-between w-full' : ''}`}>
      <p className={`text-clrTextLightGray text-xs ${!flex && 'mb-2'}`}>{label}</p>
      <p className="text-sm font-medium text-black">{value}</p>
    </div>
  </div>
);

export const ProgressBox = ({
  title,
  progressValue,
  text,
  info,
}: ProgressBoxProps) => (
  <div className="border border-[#C4C4C433] p-5 rounded-lg">
    <p className="mb-5 text-xs font-semibold text-clrTextLightGray">{title}</p>
    <div style={{ width: 200, height: 200, position: 'relative' }}>
      <CircularProgressbar
        maxValue={300}
        minValue={0}
        strokeWidth={12}
        value={progressValue}
        counterClockwise={true}
        styles={{
          path: {
            stroke: `#FFCB05`,
            strokeLinecap: 'square',
          },
          trail: {
            stroke: '#FFECE5',
          },
          text: {
            fill: '#1D1D1D',
            fontSize: '20px',
          },
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          color: '#1D1D1D',
          fontSize: '18px',
        }}
      >
        <div className="text-xs text-[#4E5969] mb-1">{text.label}</div>
        <span className="font-semibold">{text.value}</span>
      </div>
    </div>
    <div className="grid justify-end gap-4">
      {info.map((item, index) => (
        <InfoBox
          key={index}
          label={item.label}
          value={item.value}
          flex={item.flex}
          offline={item.offline}
        />
      ))}
    </div>
  </div>
);


export const SearchRow = ({ setFilterYear, setFilterTimeline, setFilterMonth, filterYear, filterTimeline, filterMonth, exportTable, dataLength }: { setFilterYear: (val: string) => void, setFilterMonth: (val: string) => void, setFilterTimeline: (val: string) => void, filterYear: string, filterTimeline: string, filterMonth: string, exportTable?: () => void, dataLength: number}) => {
  const [option, toggleOption] = useState<string>("");
  const [yearOption, toggleYearOption] = useState<string>("");
  const [monthOption, toggleMonthOption] = useState<string>("");

  const {data: listOfYears} = useGetListofYearsQuery();

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
    toggleYearOption("");
    toggleMonthOption("")
  };
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <>

      <div className="flex flex-col flex-wrap w-full gap-x-3 md:flex-row md:w-auto">
        <MobileFilterSearch>
          <Filter
            options={['Yearly', 'Monthly']}
            toggleFilter={toggleOption}
            selectedFilterOption={option}
            currentFilterOption={'TIMELINE'}
            selectFilterOption={setFilterTimeline}
            selectedOption={filterTimeline}
            modalRef={modalRef}
            label={'Timeline'}
          />
          {filterTimeline.toLowerCase() === "monthly" &&
            <Filter
              options={monthsOfYear}
              toggleFilter={toggleMonthOption}
              selectedFilterOption={monthOption}
              currentFilterOption={'MONTHLY'}
              selectFilterOption={setFilterMonth}
              selectedOption={filterMonth}
              modalRef={modalRef}
              label={'Month'}
            />}
          <Filter
            options={listOfYears?.data || []}
            toggleFilter={toggleYearOption}
            selectedFilterOption={yearOption}
            currentFilterOption={'YEAR'}
            selectFilterOption={setFilterYear}
            selectedOption={filterYear}
            modalRef={modalRef}
            label={'Year'}
          />
        </MobileFilterSearch>
        {dataLength ?
        <div className="mt-2 md:mt-3" onClick={exportTable}>
          <ButtonYellow text="Export" btnIcon={Export} to={''} />
        </div> : null}
      </div>
    </>
  )
}

interface UptimeCardProps {
  title: string;
  data: string | number | null;
  isLoading: boolean;
}

export const UptimeCard: React.FC<UptimeCardProps> = ({ title, data, isLoading }) => {
  return (
    <div className="border border-[#C4C4C433] p-5 rounded-lg">
      <h4 className="mb-2 text-xs font-semibold md:text-sm text-clrGray dark:text-clrPlaceholder">
        {title}
      </h4>
      <p className="text-2xl font-semibold text-black dark:text-white">
        {isLoading ? (
          <ScaleLoader height={12} width={2} color={'#3d5170'} />
        ) : (
          data
        )}
      </p>
    </div>
  );
};

// interface IUptimeSummary {
//   uptimeSummary: string;
//   gettingUptimeSummary: string;
// }

// export const UptimeSummary = ({ uptimeSummary, gettingUptimeSummary }: IUptimeSummary) => {
//   const uptimeMetrics = [
//     { title: 'Minimum Uptime', key: 'minimumUptime' },
//     { title: 'Average Uptime', key: 'averageUptime' },
//     { title: 'Maximum Uptime', key: 'maximumUptime' },
//     { title: 'Max Uptime of a Connected Device', key: 'maximumUptimeOfAConnectedDevice' },
//     { title: 'Min Uptime of a Connected Device', key: 'minimumUptimeOfAConnectedDevice' },
//   ];

//   return (
//     <div className="grid grid-cols-2 gap-2 py-4 rounded-lg md:pt-5 md:pb-4 xl:grid-cols-4 md:gap-4">
//       {uptimeMetrics.map((metric) => (
//         <UptimeCard
//           key={metric.key}
//           title={metric.title}
//           data={uptimeSummary?.data?.[metric.key]}
//           isLoading={gettingUptimeSummary}
//         />
//       ))}
//     </div>
//   );
// };

interface DeviceOverviewBoxProps {
  title: string;
  count: number | undefined;
  loading: boolean;
}
type OptionDetailType = {
  [key: number]: { title: string, description: string };
};

export const DeviceOverviewBox: React.FC<DeviceOverviewBoxProps> = ({ title, count, loading }) => {
  return (
    <div className="border border-[#C4C4C433] p-4 rounded-lg bg-white dark:bg-black">
      <h4 className="mb-2 text-x9 font-normal md:text-x10 text-clrTextLightGray dark:text-clrPlaceholder">
        {title}
      </h4>
      <p className="text-base font-medium text-[#000] dark:text-white">
        {loading ? (
          <ScaleLoader loading={loading} height={12} width={2} color={'#1D1D1D'} />
        ) : (
          count || 0
        )}
      </p>
    </div>
  );
};

interface OptionForConnectionProps {
  modalRef: RefObject<HTMLElement>;
  id?: string;
  macAddress?: string,
  clientAlias?: string,
  isWhitelisted?: boolean,
  isRestricted?: boolean,
  isDisconnected?: boolean,
  closeFilter: () => void;
}

export const OptionsForConnection: React.FC<OptionForConnectionProps> = ({
  modalRef,
  macAddress,
  clientAlias,
  closeFilter,
  isWhitelisted,
  isRestricted,
  id
}) => {

  const [option, setOption] = useState<number>(0);
  const [errorMsg, setError] = useState<string>('');
  const { analyticId } = useParams();
  const [whitelistDevice, { isLoading: isWhitelistingAddress }] = useWhitelistClientOnDeviceMutation();
  const [restrictDevice, {isLoading: isRestrictingAddress}] = useRestrictClientOnDeviceMutation();
  const [disconnectSession, {isLoading: isDisconnectingSession}] = useDisconnectClientOnDeviceMutation();

  const handleCloseModal = () => {
    closeFilter();
  };

  useOutsideClick(modalRef, handleCloseModal);

  const options = [
    { id: 1, label: isRestricted? 'Permit' : 'Restrict' },
    { id: 2, label: isWhitelisted ? 'Blacklist' : 'Whitelist' },
    // { id: 3, label: 'Disconnect' },
  ];

  const optionDetail: OptionDetailType = {
    1: { title: isRestricted ? `Permit Address` : "Restrict Device", description: ` ${isRestricted ? "permit": "restrict"} ${macAddress}`   },
    2: { title: isWhitelisted ? `Blacklist Address` : `Whitelist Address`, description: ` ${isWhitelisted ? "blacklist": "whitelist"} ${macAddress}` },
    // 3: { title: "Disconnect Session", description: `disconnect ${macAddress}` }
  }

  const handleRestrict = () => {
    const payload = {
      macAddress: macAddress || '',
      isRestricted: !isRestricted
    }
    restrictDevice({ payload: payload, deviceId: analyticId || '' })
    .unwrap()
    .then((res) => {
      if(res.success){
        setOption(0)
      }else{
        setError(res.message)
      }
      
    })
  };

  const handleWhitelist = () => {
    const payload = {
      macAddress: macAddress || '',
      clientAlias: clientAlias || '',
      isWhitelisted: !isWhitelisted
    }
    whitelistDevice({ payload: payload, deviceId: analyticId || '' })
      .unwrap()
      .then((res) => {
        if(res.success){
          setOption(0)
        }else{
          setError(res.message)
        }
        
      })
  };

  const handleDisconnect = () => {
    disconnectSession(id || '')
    .unwrap()
    .then((res) => {
      if(res.success){
        setOption(0)
      }else{
        setError(res.message)
      }
      
    })
  };

  const handleAction = (option: number) => {
    setError("")
    switch (option) {
      case 1: {
        handleRestrict();
        break;
      }
      case 2: {
        handleWhitelist();
        break;
      }
      case 3: {
        handleDisconnect();
        break;
      }
      default:
        break;
    }
  }

  const isLoading = () =>{
    if(isWhitelistingAddress){
      return true;
    }else if(isRestrictingAddress){
      return true
    }else if(isDisconnectingSession){
      return true
    }else{
      return false
    }
  }

  return (
    <div>
      {option &&
        <ChooseModal
          title={optionDetail[option]?.title || ''}
          description={`Are you sure you want to ${optionDetail[option]?.description}`}
          handleButton={() => handleAction(option)}
          handleModal={() => setOption(0)}
          isLoading={isLoading()}
          buttonTitle={optionDetail[option]?.title || ''}
          btnColor="bg-[#DB5F5F]"
          errMsg={errorMsg}
          icon={undefined}
        />}
      <div className="absolute z-30 w-32 p-3 pb-4 bg-white rounded dark:bg-clrDarkBg top-10 right-10">
        <ul className="space-y-2 text-xs font-normal text-[#6b6b6b] dark:text-clrPlaceholder">
          {options.map((option, index) => (
            <li
              key={index}
              className="py-1 pl-2 transition duration-150 ease-in rounded hover:bg-clrBorder dark:hover:bg-clrDarkBg2"
            >
              <button onClick={() => setOption(option.id)}>
                {option.label}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></section>
    </div>
  );
};


export const CellRendererAnalytics = ({ id, macAddress, clientAlias, isWhitelisted, isRestricted, isDisconnected}: { id?: string, macAddress?: string, clientAlias?: string, isWhitelisted?: boolean, isRestricted: boolean, isDisconnected: boolean}) => {
  const [isOption, setIsOption] = useState(false);

  const modalRefOptions = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsOption(false);
  };

  useOutsideClick(modalRefOptions, handleCloseModal);

  return (
    <div className="h-6">
      <button
        onClick={() => {
          setIsOption(!isOption);
        }}
        className="px-2 py-1 hover:bg-[#e0dcdc] hover:rounded transition duration-500 ease-in-out"
      >
        <VerticalEllipsisIcon />
      </button>
      {isOption && (
        <OptionsForConnection
          closeFilter={handleCloseModal}
          modalRef={modalRefOptions}
          id={id}
          macAddress={macAddress}
          clientAlias={clientAlias}
          isWhitelisted={isWhitelisted}
          isRestricted={isRestricted}
          isDisconnected={isDisconnected}
        />
      )}
    </div>
  );
};

export const ConnectionSearchRow = () => {
  const [filterStatus, setFilterStatus] = useState<string>('By year');
  const [option, toggleOption] = useState<string>("");
  const [filterYear, setFilterYear] = useState<string>('2024');
  const [yearOption, toggleYearOption] = useState<string>("");
  const [filterMonth, setFilterMonth] = useState<string>('January');
  const [monthOption, toggleMonthOption] = useState<string>("");

  // New function to handle filter status
  const handleFilterStatus = (status: string) => {
    if (status === 'All') {
      setFilterStatus('');
      setFilterYear('');
      setFilterMonth(''); // Set to empty string if "All" is selected
      // Set to empty string if "All" is selected
    } else {
      setFilterStatus(status); // Otherwise, set to the selected status
    }
  };

  const handleFilterYear = () => { };
  const handleFilterMonth = () => { };

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
  };
  useOutsideClick(modalRef, handleCloseModal);
  return (
    <>

      <div className="flex flex-col flex-wrap w-full gap-x-3 md:flex-row md:w-auto">
        <MobileFilterSearch>
          <Filter
            options={['By year', 'By month', 'By week', 'By day']}
            toggleFilter={toggleOption}
            selectedFilterOption={option}
            currentFilterOption={'TIMELINE'}
            selectFilterOption={handleFilterStatus}
            selectedOption={filterStatus}
            modalRef={modalRef}
            label={'Timeline'}
          />
          <Filter
            options={['January', 'February', 'March', ' April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            toggleFilter={toggleMonthOption}
            selectedFilterOption={monthOption}
            currentFilterOption={'MONTH'}
            selectFilterOption={handleFilterMonth}
            selectedOption={filterMonth}
            modalRef={modalRef}
            label={'Month'}
          />
          <Filter
            options={['2024', '2025', '2026']}
            toggleFilter={toggleYearOption}
            selectedFilterOption={yearOption}
            currentFilterOption={'YEAR'}
            selectFilterOption={handleFilterYear}
            selectedOption={filterYear}
            modalRef={modalRef}
            label={'Year'}
          />
        </MobileFilterSearch>
        <div className="mt-2 md:mt-3">
          <ButtonYellow text="Export" btnIcon={Export} to={''} />
        </div>
      </div>
    </>
  )
}