import { useGetDataUsageGraphResultsQuery, useGetDataUsageSummaryQuery } from 'src/features/api/analyticsSlice';
import { useParams } from 'react-router-dom';
import { MoreDetailsCard } from "./MoreDetails";
import { useState } from "react";
import { BarChart } from "./BarChartSession";
import { SearchRow } from './common';
import { exportTableData } from 'src/helper';
import { EmptyCard, TableLoader } from '../reusables';

interface DataUsageData {
  dataUsed: string;
  dataUsedInBytes: number;
  timePeriod: string;
}

export interface BarChartUsageProps {
  labels: string[];
  data: DataUsageData[];
}

const DataUsage = () => {
  const {analyticId } = useParams();

  // State for filters
  const [filterYear, setFilterYear] = useState<string>(new Date().getFullYear().toString());
  const [filterTimeline, setFilterTimeline] = useState<string>('Yearly');
  const [filterMonth, setFilterMonth] = useState<string>('August');

  const { data: dataUsageGraph, isLoading: gettingDataUsageGraph, isFetching: fetchingDataUsageGraph }  = useGetDataUsageGraphResultsQuery({
    deviceId: analyticId || '',
    timeLine: filterTimeline,
    month: filterMonth,
    year: filterYear
  });

  const {data, isLoading: gettingSessionSummary, isFetching: fetchingSessionSummary} = useGetDataUsageSummaryQuery({
    deviceId: analyticId || '',
    timeLine: 'Yearly',
    month: "August",
    year: "2024"
  })

  const dataUsageCards = [
    {
      title: "Average Data Usage",
      value: data?.data?.averageDataUsage,
      info: "This is the average usage that hubs have been operational within the selected timeframe",
    },
    {
      title: "Maximum Data Usage",
      value: data?.data?.maximumDataUsage,
      info: "This is the highest uptime achieved by all hubs or a selected hub within the selected timeframe.",
    },
    {
      title: "Minimum Data Usage",
      value: data?.data?.minimumDataUsage,
      info: "This is the lowest uptime achieved by all hubs or a selected hub within the selected timeframe.",
    },
    {
      title: "Avr Usage of a Connected Device",
      value: data?.data?.averageDataUsage,
      info: "This is the lowest uptime achieved by all hubs or a selected hub within the selected timeframe.",
    },
    // {
    //   title: "Max Data Usage of a Connected Device",
    //   value: data?.data?.maximumDataUsageOfAConnectedDevice,
    //   info: "This is the highest usage by a single connected device.",
    // },
    // {
    //   title: "Min Data Usage of a Connected Device",
    //   value: data?.data?.minimumDataUsageOfAConnectedDevice,
    //   info: "This is the lowest usage by a single connected device.",
    // },
  ];

  const chartData: BarChartUsageProps = {
    labels: dataUsageGraph?.data.map(item => item.timePeriod) || [],
    data: dataUsageGraph?.data.map(item => ({
      dataUsed: item.dataUsed,
      dataUsedInBytes: item.dataUsedInBytes,
      timePeriod: item.timePeriod,
    })) || []
  };

  return (
    <section className="w-[96%] mx-auto pb-6 overflow-y-auto">
      <div className="px-3 py-4 mt-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-6">
        <div>
          <h3 className="text-sm font-semibold text-clrGray dark:text-white mb-5">
            Overall Data Usage{' '}
          </h3>
          <SearchRow 
            setFilterYear={setFilterYear} 
            filterYear={filterYear} 
            filterTimeline={filterTimeline}
            setFilterTimeline={setFilterTimeline}
            setFilterMonth={setFilterMonth}
            filterMonth={filterMonth}
            exportTable={()=> exportTableData(dataUsageGraph?.data || [], "Overall Uptime")}
            dataLength={dataUsageGraph?.data.length || 0}
          />
          {gettingDataUsageGraph || fetchingDataUsageGraph ? (
              <TableLoader />
            ) : (dataUsageGraph?.data?.length || 0) === 0 ? (
              <div className="flex flex-col items-center justify-center">
                <EmptyCard description={"No result found"} />
              </div>
            ) : (
              <BarChart chartData={chartData} />
            )}
        </div>
        <div className="mt-4">
          <h3 className="text-sm font-medium text-clrGray dark:text-white">
            More Details{' '}
          </h3>
          <div className="grid md:grid-cols-2 gap-2 py-4 rounded-lg md:pt-5 md:pb-4 xl:grid-cols-4 md:gap-4">
            {dataUsageCards.map((card, index) => (
              <MoreDetailsCard
                key={index}
                title={card.title}
                value={card.value}
                info={card.info}
                isLoading={gettingSessionSummary || fetchingSessionSummary}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataUsage;
