import React, { useState, useEffect } from 'react';
import {
  Card,
  ButtonAuth,
  CardTitle,
  ErrorMsg,
  StatusPage,
} from '../reusables';
import AuthFrame from '../reusables/AuthFrame';
import { Link } from 'react-router-dom';
import AuthCode from 'react-auth-code-input';
import { useResetPassword2faMutation } from '../../features/api/authSlice';

const ResetPin = () => {
  const [pin, setPin] = useState('');
  const [confirnPin, setConfirmPin] = useState('');
  const [otp, setOTP] = useState('');
  const [email, setEmail] = useState('');
  const [isError, setError] = useState('');

  const [resetPassword, { data, isLoading }] = useResetPassword2faMutation();

  const handleChange = (res: string) => {
    if (isError) {
      setError('');
    }
    setPin(res);
  };

  const handleConfrimChange = (res: string) => {
    if (isError) {
      setError('');
    }
    setConfirmPin(res);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (pin.length !== 4) {
      setError('Please enter a valid pin');
    } else if (pin !== confirnPin) {
      setError('Pins do not match!');
    } else {
      setError('');

      let data = {
        email: email,
        otp,
        pin,
        confirmPin: confirnPin,
      };
      resetPassword(data)
      .unwrap()
      .then()
      .catch((err)=>{
        setError(err?.data?.message || "Something went wrong!")
      })
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlEmail = urlParams.get('email');
    if (urlEmail) {
      setEmail(urlEmail);
    }
  }, []);

  return (
    <div className="flex">
      <Card>
        {data?.success ? (
          <StatusPage
            title="Pin Reset!"
            user={email}
            msg={
              <p>
                {' '}
                Your Pin has been reset successfully! Go back to{' '}
                <Link className="text-primary" to="/">
                  {' '}
                  Login{' '}
                </Link>{' '}
                to continue{' '}
              </p>
            }
          />
        ) : (
          <div className="flex items-center flex-col min-[426px]:w-[350px] md:w-auto md:px-[68px] mx-auto mb-12 md:mb-8 h-full">
            <div className="text-center md:text-left">
              <CardTitle title="Reset PIN" />
              <p className="text-clrText font-semibold text-[0.625rem] md:text-sm mb-5">
                Set a new PIN for your account
              </p>
              <p className="text-clrText font-semibold text-[0.625rem] md:text-sm mb-8">
                Enter a 4 digit PIN and secure your account
              </p>
            </div>
            <div className="mb-4 text-center">
              <label
                htmlFor="pin"
                className="block text-[0.625rem] leading-3 text-black md:text-sm font-bold mb-4"
              >
                Enter OTP
              </label>
              <div className="mb-4">
                <AuthCode
                  inputClassName="w-10 h-10 text-center text-lg font-semibold text-primary border-transparent border-solid border rounded-md bg-primary/10"
                  containerClassName="flex justify-center gap-3"
                  ariaLabel="verify-otp"
                  isPassword={true}
                  autoFocus={true}
                  length={6}
                  allowedCharacters="numeric"
                  onChange={(e) => setOTP(e)}
                />
              </div>
            </div>
            <div className="mb-4 text-center">
              <label
                htmlFor="pin"
                className="block text-[0.625rem] leading-3 text-black md:text-sm font-bold mb-4"
              >
                Enter new PIN
              </label>
              <div className="mb-4">
                <AuthCode
                  inputClassName="w-10 h-10 text-center text-lg font-semibold text-primary border-transparent border-solid border rounded-md bg-primary/10"
                  containerClassName="flex justify-center gap-3"
                  ariaLabel="verify-otp"
                  isPassword={true}
                  autoFocus={false}
                  length={4}
                  allowedCharacters="numeric"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="text-center">
              <label
                htmlFor="confirmPin"
                className="block text-[0.625rem] leading-3 text-black md:text-sm font-bold mb-4"
              >
                Confirm new PIN
              </label>
              <div className="mb-4">
                <AuthCode
                  inputClassName="w-10 h-10 text-center text-lg font-semibold text-primary border-transparent border-solid border rounded-md bg-primary/10"
                  containerClassName="flex justify-center gap-3"
                  ariaLabel="verify-otp"
                  isPassword={true}
                  autoFocus={false}
                  length={4}
                  allowedCharacters="numeric"
                  onChange={handleConfrimChange}
                />
                <ErrorMsg errMsg={isError ? isError : data?.message ?? ''} />
              </div>
            </div>
            <div className="mt-6 mb-8 w-[80%]">
              <ButtonAuth
                type="submit"
                loading={isLoading}
                handleClick={handleSubmit}
                text="Set Pin"
              />
            </div>
          </div>
        )}
      </Card>
      <AuthFrame />
    </div>
  );
};

export default ResetPin;
