import React from 'react';
import MintBag from "../../assets/icons/hotspot-hubs/bag.svg";
import { useEffect, useRef, useState } from "react";
import { MobileFilterSearch } from "./OptionsStatus";
import useOutsideClick from "../../hooks/useOutsideClick";
import TableMain from "../reusables/ReactDataTable";
import { columnsReport } from "./ReactTableDataList";
import { EmptyCard, Filter, TableLoader } from "../reusables";
import {
  useGetDeviceReportQuery,
  useGetDeviceStatsQuery,
} from "../../features/api/deviceSlice";
import { ReactComponent as WalletYellow } from "../../assets/icons/hotspot-hubs/wallet-with-bg.svg";
import { ReactComponent as ChartIcon } from "../../assets/icons/hotspot-hubs/chart-with-bg.svg";
import { ReactComponent as SwitchIcon } from "../../assets/icons/hotspot-hubs/swap-with-bg.svg";
import { ScaleLoader } from "react-spinners";
import {
  calculateDateRange,
  numberWithCommas,
  numberWithCommasWithoutDecimal,
} from "../../helper";
import { filesize } from "filesize";

const HubsReport = () => {
  const [duration, setDuration] = useState<string>("All");
  const [option, toggleOption] = useState<string>("");
  const [searchValue, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const [startDateTimeStamp, setStartDateTimestamp] = useState<string>("");
  const [endDatetimeStamp, setEndDateTimeStamp] = useState<string>("");

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
  };

  useOutsideClick(modalRef, handleCloseModal);

  const { data: hubsReport, isLoading, isFetching } = useGetDeviceReportQuery({
    deviceName: searchValue,
    startDate: startDateTimeStamp,
    endDate: endDatetimeStamp,
    page: page,
    pageSize: 30
  });
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const { data: deviceStats, isLoading: gettingDeviceStats } =
    useGetDeviceStatsQuery({
      hubId: "",
      search: searchValue,
      duration: "Custom",
      startDateTimeStamp: startDateTimeStamp,
      endDatetimeStamp: endDatetimeStamp,
    });


  const handleDateRangeChange = (range: string) => {
    setDuration(range);
    const [newStartDate, newEndDate] = calculateDateRange(range);
    setStartDate("");
    setEndDate("");
    setStartDateTimestamp(newStartDate?.toString() || "");
    setEndDateTimeStamp(newEndDate?.toString() || "");
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    setStartDateTimestamp((selectedDate.getTime()).toString());
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    setEndDateTimeStamp((selectedDate.getTime()).toString());
  };

  useEffect(() => {
    handleDateRangeChange("Last Week")
  }, []);

  return (
    <section className="w-[96%] mx-auto mt-3 mb-6 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-5">
        <div className="flex flex-wrap items-end gap-4 mb-8 md:px-3">
          <div className="flex w-full gap-3 md:w-auto">
            <MobileFilterSearch>
              <Filter
                modalRef={modalRef}
                options={[
                  "Last Week",
                  "Last Month",
                  "Last 3 Months",
                  "Last Year",
                  "Custom"
                ]}
                toggleFilter={toggleOption}
                selectedFilterOption={option}
                currentFilterOption={"DURATION"}
                selectFilterOption={handleDateRangeChange}
                selectedOption={duration}
                label={"Duration"}
              />
              {duration === "Custom" &&
              <>
                <div className="relative">
                  <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel">
                    Start Date
                  </h4>
                  <input
                    type="date"
                    className="w-full md:w-52 text-x10 text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                    value={startDate}
                    onChange={(e) => handleStartDateChange(e)}
                  />
                </div>
                <div className="relative">
                  <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel">
                    End Date
                  </h4>
                  <input
                    type="date"
                    className="w-full md:w-52 text-x10 border-[1px] text-clrPlaceholder border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                    value={endDate}
                    onChange={(e) => handleEndDateChange(e)}
                  />
                </div>
              </>
            }
            </MobileFilterSearch>
            <div className="flex-1 md:flex-auto ">
              <label
                htmlFor=""
                className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
              >
                Search
              </label>
              <input
                type="text"
                name=""
                id=""
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Hub Name"
                className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
              />
            </div>
          </div>

          {/* <ButtonYellow text="Export Report" btnIcon={whitepaper} to={""} /> */}
        </div>
        <div className="grid grid-cols-1 gap-2 my-6 md:px-3 sm:grid-cols-2 xl:grid-cols-3 md:gap-3">
          <div className="flex items-center gap-2 md:gap-4 bg-white dark:border-[#C4C4C44D] dark:bg-clrDarkBg rounded-[10px] p-3 h-[100px] border-[1px] border-solid border-[#c4c4c433]">
            <WalletYellow width={35} height={35} />
            <div className="mr-auto">
              <p className="text-xs font-semibold text-[#747474] dark:text-clrPlaceholder ">
                Revenue
              </p>
              <p className="text-xl font-semibold text-[#1d1d1d] dark:text-white mt-3">
                {gettingDeviceStats ? (
                  <ScaleLoader
                    loading={isLoading}
                    height={12}
                    width={2}
                    color={"#3d5170"}
                  />
                ) : (
                  "NGN "+ numberWithCommas(deviceStats?.data?.totalRevenueInUSD || 0)
                )}
              </p>
            </div>
            {/* <div className="flex items-center self-end gap-2 pb-2">
                  <img src={ChevronUp} alt="Increase" width={15} height={15} />
                  <p className="text-xs font-normal text-[#5FBE91]">
                    {item.rate}
                  </p>
                </div> */}
          </div>
          <div className="flex items-center gap-2 md:gap-4 bg-white dark:border-[#C4C4C44D] dark:bg-clrDarkBg rounded-[10px] p-3 h-[100px] border-[1px] border-solid border-[#c4c4c433]">
            <ChartIcon width={35} height={35} />
            <div className="mr-auto">
              <p className="text-xs font-semibold text-[#747474] dark:text-clrPlaceholder">
                Sessions
              </p>
              <p className="text-xl font-semibold text-[#1d1d1d] dark:text-white mt-3">
                {gettingDeviceStats ? (
                  <ScaleLoader
                    loading={isLoading}
                    height={12}
                    width={2}
                    color={"#3d5170"}
                  />
                ) : (
                  numberWithCommasWithoutDecimal(
                    deviceStats?.data?.totalSessions || 0
                  )
                )}
              </p>
            </div>
            {/* <div className="flex items-center self-end gap-2 pb-2">
                  <img src={ChevronUp} alt="Increase" width={15} height={15} />
                  <p className="text-xs font-normal text-[#5FBE91]">
                    {item.rate}
                  </p>
                </div> */}
          </div>
          <div className="flex items-center gap-2 md:gap-4 bg-white dark:border-[#C4C4C44D] dark:bg-clrDarkBg rounded-[10px] p-3 h-[100px] border-[1px] border-solid border-[#c4c4c433]">
            <SwitchIcon width={35} height={35} />
            <div className="mr-auto">
              <p className="text-xs font-semibold text-[#747474] dark:text-clrPlaceholder">
                Data Used
              </p>
              <p className="text-xl font-semibold text-[#1d1d1d] dark:text-white mt-3">
                {gettingDeviceStats ? (
                  <ScaleLoader
                    loading={isLoading}
                    height={12}
                    width={2}
                    color={"#3d5170"}
                  />
                ) : (
                  filesize(deviceStats?.data?.dataUsed || 0)
                )}{" "}
              </p>
            </div>
            {/* <div className="flex items-center self-end gap-2 pb-2">
              <img src={ChevronUp} alt="Increase" width={15} height={15} />
              <p className="text-xs font-normal text-[#5FBE91]">
                {item.rate}
              </p>
            </div> */}
          </div>
        </div>
        <div>
          {isLoading || isFetching ? (
            <TableLoader />
          ) : (
            <TableMain 
              defaultData={hubsReport?.data?.records || []} 
              columns={columnsReport} 
              showPagingation={true}
              totalPages={hubsReport?.data?.totalPages || 0}
              pageSize={hubsReport?.data?.pageSize || 0}
              currentPage={hubsReport?.data?.currentPage || 0}
              setPage={setPage}

            />
          )}
        </div>
        {!isLoading && !isFetching && hubsReport?.data?.records?.length === 0 && (
          <div className="flex flex-col items-center justify-center">
            <EmptyCard description="No result found" />
            <a
              href="https://shop.mtn.ng"
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-between items-center w-fit h-8 mt-3 px-4 gap-3 bg-primary rounded-[28px] transition duration-500 ease-in-out transform hover:bg-[#e3be27] hover:scale-[0.95]"
            >
              <img src={MintBag} alt="Buy Hub" width={18} height={18} />
              <p className="text-xs font-medium text-white">{"Buy Hub"}</p>
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default HubsReport;
