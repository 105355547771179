import { apiSlice } from './apiSlice';
import {
  AdSupportRequest,
  AllDevicesRequest,
  AllDevicesResponse,
  BillingRateRequest,
  CaptivePortalRequest,
  DeviceReportRequest,
  DeviceReportResponse,
  DeviceSummaryResponse,
  GetSessionequest,
  GetSessionSummaryRequest,
  HubStatsRequest,
  HubStatsResponse,
  PaginatedSuccessResponse,
  RestrictRequest,
  SessionSummaryResponse,
  SuccessResponse,
  WhitelistRequest,
} from './deviceTypes';

const deviceSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    //Get Devices
    getDevices: build.query<AllDevicesResponse, AllDevicesRequest>({
      query: ({ deviceName, deviceStatus, page, pageSize }) =>
        `/v2/devices/my?deviceName=${deviceName}&deviceStatus=${deviceStatus}&page=${page}&pageSize=${pageSize}`,
      providesTags: ['Devices'],
    }),

    //Get Session Summary
    getSessionSummary: build.query<
      SessionSummaryResponse,
      GetSessionSummaryRequest
    >({
      query: ({
        isActive,
        searchParam,
        deviceId,
        userId,
        businessid,
        startDateTimeStamp,
        endDatetimeStamp,
      }) =>
        `/v2/devices/sessions/summary?isActive=${isActive}&searchParam=${searchParam}&deviceId=${deviceId}&userId=${userId}&businessid=${businessid}&startDateTimeStamp=${startDateTimeStamp}&endDatetimeStamp=${endDatetimeStamp}`,
      providesTags: ['Devices'],
    }),

    //Get User Sessions
    getSessions: build.query<PaginatedSuccessResponse, GetSessionequest>({
      query: ({
        isActive,
        pageSize,
        page,
        startDateTimeStamp,
        endDatetimeStamp,
        deviceName,
      }) =>
        `/v2/devices/Sessions/user?isActive=${isActive}&pageSize=${pageSize}&page=${page}&startDateTimeStamp=${startDateTimeStamp}&endDatetimeStamp=${endDatetimeStamp}&deviceName=${deviceName}`,
      providesTags: ['Devices'],
    }),

    //Get Device Details
    getDeviceSummary: build.query<DeviceSummaryResponse, void>({
      query: () => `/v4/hotspot-hubs/status`,
      providesTags: ['Devices'],
    }),

    //Get device report
    getDeviceReport: build.query<DeviceReportResponse, DeviceReportRequest>({
      query: ({ deviceName, startDate, endDate, page, pageSize }) =>
        `/v2/users/devices/summary/by?deviceName=${deviceName}&startDate=${startDate}&endDate=${endDate}&Page=${page}&PageSize=${pageSize}`,
      providesTags: ['Devices'],
    }),

    //Delete Hub
    deleteDevice: build.mutation<SuccessResponse, Partial<string>>({
      query: (id) => ({
        url: `/v2/devices/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Devices'],
    }),

    //Device Stats
    getDeviceStats: build.query<HubStatsResponse, HubStatsRequest>({
      query: ({
        hubId,
        search,
        duration,
        startDateTimeStamp,
        endDatetimeStamp,
      }) =>
        `/v4/hotspot-hubs/stats?HubId=${hubId}&Duration=${duration}&Search=${search}&StartDateTimeStamp=${startDateTimeStamp}&EndDateTimeStamp=${endDatetimeStamp}`,
      providesTags: ['Devices'],
    }),

    //Toggle ad support
    toggleAdSupport: build.mutation<SuccessResponse, Partial<AdSupportRequest>>(
      {
        query: (payload) => ({
          url: `/v2/devices/adSupport`,
          method: 'PUT',
          body: payload,
        }),
        invalidatesTags: ['Devices'],
      }
    ),

    //Set billing rate for device
    setBillingRate: build.mutation<any, Partial<BillingRateRequest>>({
      query: (payload) => ({
        url: `/v4/hotspot-hubs/billing-rate`,
        method: 'PUT',
        body: payload,
      }),

      // Add onQueryStarted to conditionally invalidate tags
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            apiSlice.util.invalidateTags(['Devices'])
          );
        } catch {}
      },
    }),

    //Toggles the captive portal setting for a device
    toggleCaptivePortal: build.mutation<SuccessResponse, CaptivePortalRequest>(
      {
        query: ({ payload, deviceId }) => ({
          url: `/v4/captive-portal/${deviceId}`,
          method: 'PUT',
          body: payload,
        }),
        invalidatesTags: ['Devices'],
      }
    ),

    //Whitelist a mac address
    whitelistClientOnDevice: build.mutation<SuccessResponse, WhitelistRequest>(
      {
        query: ({ payload, deviceId }) => ({
          url: `/v4/hotspot-hubs/whitelisted-client/device/${deviceId}`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['Devices'],
      }
    ),

    //Restrict a mac addres
    restrictClientOnDevice: build.mutation<SuccessResponse, RestrictRequest>(
      {
        query: ({ payload, deviceId }) => ({
          url: `/v4/hotspot-hubs/restricted-client/device/${deviceId}`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['Devices'],
      }
    ),

    //Disconnect a mac addres
    disconnectClientOnDevice: build.mutation<SuccessResponse, string>(
      {
        query: (sessionId) => ({
          url: `/v4/hotspot-hubs/session/device/${sessionId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Devices'],
      }
    ),

  }),
  overrideExisting: true,
});

export const {
  useGetDevicesQuery,
  useGetSessionSummaryQuery,
  useToggleAdSupportMutation,
  useGetSessionsQuery,
  useSetBillingRateMutation,
  useDeleteDeviceMutation,
  useLazyGetDevicesQuery,
  useGetDeviceSummaryQuery,
  useGetDeviceStatsQuery,
  useGetDeviceReportQuery,
  useToggleCaptivePortalMutation,
  useWhitelistClientOnDeviceMutation,
  useRestrictClientOnDeviceMutation,
  useDisconnectClientOnDeviceMutation
} = deviceSlice;
