import { clusterSlice } from "./apiSlice";
import { AddClusterRequest, ClusterListRequest, ClusterListResponse } from "./clusterSliceTypes";



const cluster = clusterSlice.injectEndpoints({
    endpoints: (build) => ({

        //Get Clusters
        getClusters: build.query<ClusterListResponse, ClusterListRequest>({ 
            query: ({search, page, pageSize, businessId}) => `/v1/device-list/business/${businessId}?searchParam=${search}&page=${page}&pageSize=${pageSize}`,
            providesTags: []
        }),

         //Add Cluster
        addCluster: build.mutation<any, Partial<AddClusterRequest>>({
            query: ({businessId,  payload}) => ({
            url: `/v1/device-list/business/${businessId}`,
            method: 'POST',
            body: payload,
            }),
            invalidatesTags: ['Cluster'],
        }),
        
    }),
    overrideExisting: true,
})

export const { useGetClustersQuery, useAddClusterMutation} = cluster