import DeviceList from "./DeviceList";

const Subscription = () => {
    return (
        <section className="w-[96%] mx-auto mt-3 mb-6 overflow-y-auto">
            <div className="px-4 pt-2.5 pb-20 bg-white dark:bg-clrDarkBg rounded-lg md:px-10">
                <DeviceList />
            </div>
        </section>
    )
}

export default Subscription