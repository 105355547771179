import TableMain from "../reusables/ReactDataTable";
import { trafficColumn } from "./data";
import { SearchRow } from "./common";
import { DoughnutChart } from "./DoughnutChart";
import { EmptyCard, TableLoader } from "../reusables";
import { exportTableData, getCategoryColor } from "src/helper";

interface InfoBoxProps {
  label: string;
  value: number;
  infoBg: string;
}

interface ChartDataItem {
  label: string;
  value: number;
  color: string;
  infoBg: string;
}

interface TrafficData {
  category: {
    name: string;
  };
  percentageOfTotalVisits: number;
}

interface TrafficSummaryProps {
  data: { data: TrafficData[] } | undefined;
  isLoading: boolean;
  isFetching: boolean;
  page: number;
  setPage: (page: number) => void;
  showSearchRow?: boolean;
  filterYear?: string;
  setFilterYear?: (year: string) => void;
  filterTimeline?: string;
  setFilterTimeline?: (timeline: string) => void;
  filterMonth?: string;
  setFilterMonth?: (month: string) => void;
}

const TrafficSummary = ({ 
  data, 
  isLoading, 
  isFetching, 
  page, 
  setPage,
  showSearchRow = false,
  filterYear,
  setFilterYear,
  filterTimeline,
  setFilterTimeline,
  filterMonth,
  setFilterMonth
}: TrafficSummaryProps) => {
  return (
    <div className="flex flex-col gap-4 mt-10 xl:flex-row">
      <TrafficAnalyticsChart 
        data={data} 
        isLoading={isLoading} 
        isFetching={isFetching} 
        />
      <div className="border border-[#C4C4C433] rounded-lg w-full xl:w-[55%] pb-5">
        <div className="px-5 pt-5">
          <h4 className="mb-3 text-xs font-medium text-clrTextLightGray dark:text-white">
            Traffic Breakdown
          </h4>
        </div>
        {showSearchRow && filterYear && setFilterYear && filterTimeline && setFilterTimeline && filterMonth && setFilterMonth && (
          <div className="grid gap-7 px-7 pt-3">
            <SearchRow 
              setFilterYear={setFilterYear} 
              filterYear={filterYear} 
              filterTimeline={filterTimeline}
              setFilterTimeline={setFilterTimeline}
              setFilterMonth={setFilterMonth}
              filterMonth={filterMonth}
              dataLength={data?.data?.length || 0}
              exportTable={() => exportTableData(data?.data || [], "Traffic Breakdown")}
            />
          </div>
        )}
        {isLoading || isFetching ? (
          <TableLoader />
        ) : (
          <TableMain
            defaultData={data?.data || []}
            columns={trafficColumn}
            totalPages={0}
            pageSize={30}
            currentPage={page}
            setPage={setPage}
          />
        )}

        {!isLoading && !isFetching && (data?.data?.length || 0) === 0 && (
          <EmptyCard description="You don't have any traffic analytics!" />
        )}
      </div>
    </div>
  )
}

export default TrafficSummary

const TrafficAnalyticsChart = ({ 
  data, 
  isLoading, 
  isFetching 
}: { 
  data: { data: TrafficData[] } | undefined, 
  isLoading: boolean, 
  isFetching: boolean 
}) => {

  const chartData: ChartDataItem[] = data?.data?.map((each: TrafficData) => {
    const color = getCategoryColor(each?.category?.name);
    return {
      label: each?.category?.name,
      value: each?.percentageOfTotalVisits,
      color: color,
      infoBg: color,
    };
  }) || [];

  return (
    <div className="grid gap-6 md:gap-12 border border-[#C4C4C433] px-4 md:px-6 md:pt-6 py-6 md:pb-14 rounded-lg w-full xl:w-[45%]">
      {isLoading || isFetching ? (
        <TableLoader />
      ) : data?.data?.length ? (
        <DoughnutChart data={chartData} />
      ) : (
        <h4 className="mb-3 text-xs font-medium text-clrTextLightGray dark:text-white">
          Traffic Analytics
        </h4>
      )}
      <div className="grid grid-cols-2 gap-3 md:gap-5 md:grid-cols-3 ">
        {chartData?.map((each: any) => (
          <DynamicInfoBox key={each?.label} label={each.label} value={each.value} infoBg={each.infoBg} />
        ))}
      </div>
      {!isLoading && !isFetching && (data?.data?.length || 0) === 0 && (
        <EmptyCard description="You don't have any traffic analytics!" />
      )}
    </div>
  );
};

const DynamicInfoBox = ({ label, value, infoBg }: InfoBoxProps) => (
  <div className="flex gap-2 md:w-[120px]">
    <div
      style={{ backgroundColor: infoBg }}
      className={`w-1 rounded h-[20px]`}
    />
    <div className={`flex justify-between items-center w-full`}>
      <p className={`text-[#4E5969] text-x10`}>{label}</p>
      <p className="text-[#1D2129] text-[15px] leading-[21px] font-medium">{value}
        <span className="font-normal text-[#86909C] text-x10">%</span>
      </p>
    </div>
  </div>
);