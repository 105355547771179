import { ReactElement, useState } from "react";
import { Country } from "../../../features/api/authSliceTypes";
// import { ModalProps } from "../../reusables/Modal";
import { useGetSupportedCountriesQuery } from "../../../features/api/authSlice";
import SearchIcon from "../../../assets/search.svg";
import React, { useRef } from "react";
import { ReactComponent as ArrowBack } from "../../../assets/icons/arrow-back.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/hotspot-hubs/close.svg";
import { TableLoader } from "../../reusables";
import useOutsideClick from "src/hooks/useOutsideClick";

interface ModalProps {
  handleCloseModal: () => void;
  children: ReactElement;
  title?: string;
  close?: boolean;
  description?: string;
  goBack?: boolean;
  handleGoBack?: () => void; 
}


export const Modal: React.FC<ModalProps> = ({
  handleCloseModal,
  children,
  title,
  close,
  description,
  goBack,
  handleGoBack,
}) => {
  const modalRef = useRef<HTMLElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <section
      className="fixed inset-0 z-[55] h-screen overflow-hidden bg-[#F4F4F4] md:bg-[#00000033] flex justify-center md:items-center"
      ref={modalRef}
    >
      <div className="bg-clrBg2 py-6 z-[66] rounded-xl w-11/12 md:px-4 md:w-[450px]">
        {goBack && (
          <button className="mb-2 md:mb-3 btn-reset" onClick={handleGoBack}>
            <ArrowBack className="w-5 h-5" />
          </button>
        )}

        <div className={`flex items-start ${!title ? "justify-end" : "justify-between"}`}>
          {title && (
            <div className="mb-3">
              <h1 className="mb-2 text-base font-semibold text-black">
                {title}
              </h1>
              <p className="font-normal text-x10 md:text-xs text-clrGray">
                {description}
              </p>
            </div>
          )}

          {close && (
            <button onClick={handleCloseModal} className={`${!title && !goBack && "mb-3"}`}>
              <CloseIcon className="w-[13px] h-[13px] self-end" />
            </button>
          )}
        </div>
        {children}
      </div>
    </section>
  );
};

export interface SelectionOption {
  id: string;
  name: string;
  logoUrl?: string;
  code?: string;
}

interface BaseModalProps {
  handleGoBack: () => void;
  handleCloseModal: () => void;
}

interface CountryModalProps extends BaseModalProps {
  type: 'country';
  handleSubmit: (value: Country) => void;
  options?: never;
}

interface LanguageModalProps extends BaseModalProps {
  type: 'language';
  handleSubmit: (value: SelectionOption) => void;
  options: SelectionOption[];
}

export type SelectModalProps = CountryModalProps | LanguageModalProps;

export const SelectModal: React.FC<SelectModalProps> = ({ 
  handleCloseModal, 
  handleSubmit, 
  handleGoBack,
  type,
  ...props
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  
  const { data: countries, isLoading: isLoadingCountries } = useGetSupportedCountriesQuery({
    page: 1,
    pageSize: 195,
    search: searchQuery,
  }, {
    skip: type !== 'country'
  });

  const [isLoadingLanguages, setIsLoadingLanguages] = useState(true);
  
  // Simulate loading state for languages (remove if you have actual loading state)
  React.useEffect(() => {
    if (type === 'language') {
      const timer = setTimeout(() => setIsLoadingLanguages(false), 100);
      return () => clearTimeout(timer);
    }
  }, [type]);

  const renderContent = () => {
    if (type === 'country') {
      if (isLoadingCountries) {
        return <TableLoader />;
      }

      if (countries?.data?.records) {
        return countries.data.records
          .filter(country => country.name.toLowerCase().includes(searchQuery.toLowerCase()))
          .map(country => (
            <button
              key={country.countryId}
              onClick={() => handleSubmit(country)}
              className="py-3 w-full border-b-[0.0625rem] border-solid border-clrBorder3"
            >
              <div className="flex items-center gap-4">
                {country.logoUrl && (
                  <img 
                    src={country.logoUrl} 
                    alt={country.name} 
                    className="h-5 w-auto"
                  />
                )}
                <p className="font-semibold text-left text-clrGray text-x9 active:text-primary focus:text-primary hover:text-primary">
                  {country.name}
                </p>
              </div>
            </button>
          ));
      }
    }

    if (type === 'language') {
      if (isLoadingLanguages) {
        return <TableLoader />;
      }

      const languageProps = props as LanguageModalProps;
      return languageProps.options
        .filter(option => option.name.toLowerCase().includes(searchQuery.toLowerCase()))
        .map(option => (
          <button
            key={option.id}
            onClick={() => handleSubmit(option)}
            className="py-3 w-full border-b-[0.0625rem] border-solid border-clrBorder3"
          >
            <div className="flex items-center gap-4">
              {option.logoUrl && (
                <img 
                  src={option.logoUrl} 
                  alt={option.name} 
                  className="h-5 w-auto"
                />
              )}
              <p className="font-semibold text-left text-clrGray text-x9 active:text-primary focus:text-primary hover:text-primary">
                {option.name}
              </p>
            </div>
          </button>
        ));
    }

    return null;
  };

  return (
    <Modal
      close={true}
      goBack={true}
      title={type === 'country' ? "Select Region": "Select Language"}
      handleGoBack={handleGoBack}
      handleCloseModal={handleCloseModal}
    >
      <div className="p-6 bg-white rounded">
        <div className="relative mb-3">
          <label htmlFor="search" className="hidden">
            Search
          </label>
          <img
            src={SearchIcon}
            alt={'Search'}
            className="absolute top-2.5 left-5"
            width={13}
            height={13}
          />
          <input
            type="text"
            name="search"
            placeholder={type === 'country' ? "Search Country" : "Search Language"}
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            className="w-full indent-8 bg-clrBg3 border-[1px] border-solid border-clrBorder4 rounded-3xl h-8 px-4 placeholder:text-clrPlaceholder placeholder:text-x10 text-xs outline-none"
          />
        </div>
        <div className="h-40 overflow-auto">
          {renderContent()}
        </div>
      </div>
    </Modal>
  );
};