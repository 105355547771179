import { useRef, useState } from "react";
import TableMain from "../reusables/ReactDataTable";
import { myCluster } from "./data";
import { EmptyCard, TableLoader } from "../reusables";
import AddIcon from "../../assets/icons/wallet/add.svg";
import { useAppSelector } from "src/app/hooks";
import { useGetClustersQuery } from "src/features/api/clusterSlice";
import { AddClusterModal } from "./clusterModals";
import useOutsideClick from "src/hooks/useOutsideClick";
// import CopyIcon from "../../assets/icons/copy.svg";


const Clusters = () => {
    const { businessId } = useAppSelector((state) => state.auth);
    const [page, setPage] = useState<number>(1);
    const [searchValue, setSearch] = useState<string>("");
    const [isCluster, setAddCluster] = useState<boolean>(false);
    const { data, isLoading, isFetching } = useGetClustersQuery({
        page,
        pageSize: 30,
        businessId: businessId,
        search: searchValue
    });


    const modalRef = useRef<HTMLElement>(null);

    useOutsideClick(modalRef, ()=> setAddCluster(!isCluster));

    return (
        <section className="w-[96%] mx-auto mt-3 mb-6 overflow-y-auto">
            <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-8">
                <h3 className="text-sm font-semibold text-clrGray dark:text-clrPlaceholder">
                    Clusters
                </h3>
                <div className="flex flex-wrap items-end gap-4 my-4 md:mt-6 md:mb-8 md:flex-nowrap">
                    <div>
                        <label
                            htmlFor=""
                            className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
                        >
                            Search
                        </label>
                        <input
                            type="text"
                            name=""
                            id=""
                            value={searchValue}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Cluster Name"
                            className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
                        />
                    </div>
                    <button
                        type="button"
                        onClick={()=> setAddCluster(!isCluster)}
                        className="cursor-pointer flex justify-between items-center w-fit h-8 mt-3 px-4 gap-3 bg-primary rounded-[28px] transition duration-500 ease-in-out transform hover:bg-[#e3be27] hover:scale-[0.95]"
                    >
                        <img src={AddIcon} alt="Add URL" width={18} height={18} />
                        <p className="text-xs font-medium text-white">{"Add Cluster"}</p>
                    </button>
                </div>

                <div>
                    {isLoading || isFetching ? (
                        <TableLoader />
                    ) : (
                        <TableMain
                            defaultData={data?.data?.records || []}
                            columns={myCluster}
                            totalPages={data?.data.totalPages || 0}
                            pageSize={data?.data.pageSize || 0}
                            currentPage={data?.data.currentPage || 0}
                            setPage={setPage}
                        />
                    )}
                    {!isLoading && !isFetching && (!data || !data.data || data.data.records.length === 0) && (
                        <EmptyCard
                            description={searchValue ? `${searchValue} not found` : "No cluster found" }
                        />
                    )}
                </div>
            </div>
            {isCluster && <AddClusterModal modalRef={modalRef} handleCloseModal={()=> setAddCluster(!isCluster) } />}
        </section>
    );
};

export default Clusters;
